import { useEffect } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';

const FEATURE_FLAG = 'bite_sized_feature_tasting_premium_messages';

export const useEligibilityBiteSizedPremiumMessages = () => {
	const statsigFeatureFlagValue = FeatureGates.getExperimentValue<string>(
		FEATURE_FLAG,
		'cohort',
		'not-enrolled',
		{ fireExperimentExposure: false },
	);

	useEffect(() => {
		if (statsigFeatureFlagValue === 'experiment' || statsigFeatureFlagValue === 'control') {
			FeatureGates.manuallyLogExperimentExposure(FEATURE_FLAG);
		}
	}, [statsigFeatureFlagValue]);

	const isFeatureFlagEnabled = statsigFeatureFlagValue === 'experiment';

	/**
	 * TODO
	 * Eligibility check for Bite Sized Premium Messages not dismissed
	 * Add RT check here
	 * Add  trialEndDate < 28 days
	 * Add in Trial check:  trialEndDate - today > 0 && edition premium
	 */

	return {
		isFeatureFlagEnabled,
	};
};
